import { SET_MEMBER_COMPANIES } from '@/core/services/store/common.module';

import axios from 'axios';
import store from '@/core/services/store';

export async function getCompanies() {
  try {
    const res = await axios.get('/company/publiclist');
    if (res.status === 200) {
      store.dispatch(SET_MEMBER_COMPANIES, res.data);
    }
  } catch (err) {
    console.error(err);
  }
}
